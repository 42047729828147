const formRoutes = [

    {
        path: '/form/list',
        name: 'FormListRouter',
        component: () => import('@/views/form/list.vue'),
        meta: { mainShow: true },
    }, {
        path: '/form/info',
        name: 'FormInfoRouter',
        component: () => import('@/views/form/info.vue'),
        meta: { mainShow: true },
    }, {
        path: '/form/alllist',
        name: 'FormAllListRouter',
        component: () => import('@/views/form/all_list.vue'),
        meta: { mainShow: true },
    }, {
        path: '/form/add/excel',
        name: 'FormExcelRouter',
        component: () => import('@/views/form/excel_add.vue'),
        meta: { mainShow: true },
    }, {
        path: '/form/sdfp',
        name: 'FormSDFPRouter',
        component: () => import('@/views/form/sdfp.vue'),
        meta: { mainShow: true },
    },
    {
        path: '/form/add/one',
        name: 'FormAddOneRouter',
        component: () => import('@/views/form/add_one.vue'),
        meta: { mainShow: true },
    }, {
        path: '/form/geteverydayformcount',
        name: 'GetEveryDayFormCount',
        component: () => import('@/views/form/everydayformcount.vue'),
        meta: { mainShow: true },
    },

]

export default formRoutes
