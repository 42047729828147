import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui' // 引入element
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import publicJs from './js/public.js'

Vue.config.productionTip = false
Vue.prototype.myAxios = axios
// Vue.prototype.myBaseurl = "http://localhost:9002"
Vue.prototype.myBaseurl = "https://xsgl.dreamsun.net/apis"
axios.defaults.headers['Content-Type'] = 'application/json'

Vue.use(publicJs)
Vue.use(ElementUI)
Vue.use(axios)
// 注册全局title
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

