<template>
    <el-container>
        <el-header>
            <div class="header_top">
                <div v-if="showMenu" class="topLogo" style="font-size:26px; font-weight:600;color:#409eff;"> 表单后台 </div>
                <div v-if="showMenu">欢迎：<span>{{ user.comp_name }}</span> 您目前是<span> {{ user.level_name }} </span>!
                </div>
                <div>
                    <el-button type="primary" @click="showMenuFunc">隐藏/显示菜单</el-button>
                </div>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px" v-if="showMenu">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>
                    <slot name='main'></slot>
                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
import LeftMenu from './left.vue'
import Top from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'ConteinerView',
    components: {
        LeftMenu,
        Top,
        Footer
    },

    data() {
        return {
            showMenu: true,
            user: {},
        }
    },

    created() {
        // 从缓存获取user
        const user = sessionStorage.getItem('user')
        this.user = JSON.parse(user)
        switch (this.user.user_level) {
            case 1:
                this.user.level_name = "超级管理员"
                break;
            case 2:
                this.user.level_name = "管理员"
                break;
            case 3:
                this.user.level_name = "普通用户"
                break;
        }
    },
    methods: {
        showMenuFunc() {
            if (this.showMenu) {
                this.showMenu = false
            } else {
                this.showMenu = true
            }
        },
    }
}
</script>

<style scoped>
.header_top span:nth-child(2) {
    color: #ff5600;
    font-weight: bold;
}

.header_top span:nth-child(3) {
    color: #409eff;
    font-weight: bold;
}

.header_top {
    background-color: #ffffff !important;
    color: black;
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 14px;
}
</style>>