const userRoutes = [{
    path: '/user/index',
    name: 'UserIndexRouter',
    component: () => import('@/views/user/index.vue'),
    meta: { mainShow: true },
}, {
    path: '/user/list',
    name: 'UserListRouter',
    component: () => import('@/views/user/list.vue'),
    meta: { mainShow: true },
}, {
    path: '/user/add',
    name: 'UserAddRouter',
    component: () => import('@/views/user/add.vue'),
    meta: { mainShow: true },
}, {
    path: '/user/info',
    name: 'UserInfoRouter',
    component: () => import('@/views/user/info.vue'),
    meta: { mainShow: true },
}]

export default userRoutes