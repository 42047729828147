const loginRoutes = [{
    path: '/',
    redirect: "/public/login",
    meta: { loginShow: true },
},
{
    path: '/public/login',
    name: 'LoginRouter',
    component: () => import('@/views/public/login.vue'),
    meta: { loginShow: true },
},
{
    path: '/public/auth',
    name: 'AuthRouter',
    component: () => import('@/views/public/auth.vue'),
    meta: { mainShow: true },
}
]

export default loginRoutes
