import Vue from 'vue'
import VueRouter from 'vue-router'
import loginRoutes from "@/router/public/login.js"
import userRoutes from "@/router/user/user.js"
import formRoutes from "@/router/form/form.js"

Vue.use(VueRouter)

const routes = [
    ...loginRoutes,
    ...userRoutes,
    ...formRoutes

]

const router = new VueRouter({
    routes
})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    if (to.path === '/public/login') {
        next();
    } else {
        let token = sessionStorage.getItem('user');
        if (token === null || token === '') {
            next('/public/login');
        } else {
            next();
        }
    }
});

export default router