<template>
    <div class="footer">{{cache_user.comp_name}}版权所有&copy;·翻版必究 </div>
</template>

<script>
export default {
    name: 'HeaderTop',
    data() {
        return {
            cache_user: {},
        }
    },
    created() {
        // 从缓存获取user
        let that = this
        const user_json = sessionStorage.getItem('user')
        let user_obj = JSON.parse(user_json)
        if (user_obj.user_level == 1 || user_obj.user_level == 2) {
            that.cache_user = user_obj
        }
    },
}

</script>

<style scoped="scoped">
</style>