<template>
    <div class="left_menu">
        <el-menu class="el-menu-vertical" :default-openeds="openeds" background-color="#263238" text-color="#ffffff" active-text-color="#FF5600" :default-active="activerouter" :unique-opened="true">
            <el-submenu index="1">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>会员管理</span>
                </template>
                <el-menu-item-group>
                    <router-link :to="{path:'/user/list'}">
                        <el-menu-item index="/user/list">会员列表</el-menu-item>
                    </router-link>
                    <router-link to="/user/add">
                        <el-menu-item v-if="qxgl" index="/user/add">新增会员</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>

            <el-submenu index="2" v-if="qxgl">
                <template slot="title">
                    <i class="el-icon-s-fold"></i>
                    <span>表单管理</span>
                </template>
                <el-menu-item-group>
                    <router-link :to="{path:'/form/alllist'}">
                        <el-menu-item index="/form/alllist">全部表单</el-menu-item>
                    </router-link>
                    <router-link :to="{path:'/form/add/one'}">
                        <el-menu-item index="/form/add/one">新增表单</el-menu-item>
                    </router-link>
                    <router-link :to="{path:'/form/geteverydayformcount'}">
                        <el-menu-item index="/form/geteverydayformcount">表单统计</el-menu-item>
                    </router-link>
                </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="3" @click="signOut">
                <i class="el-icon-circle-close"></i>
                <span slot="title">退出系统</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'LeftMenu',
    data() {
        return {
            activerouter: '',
            qxgl: false,
            openeds: ['1'],
        }
    },

    created() {
        // 从缓存获取user
        const user_json = sessionStorage.getItem('user')
        if (user_json == "" || !user_json || user_json == "undefined") {
            that.$router.push({
                path: '/public/login',
            })
            return
        }
        let user_obj = JSON.parse(user_json)
        if (user_obj.user_level == 1 || user_obj.user_level == 2) {
            this.qxgl = true
        }
    },

    mounted() {
        this.activerouter = this.$route.path
    },
    methods: {
        signOut() {
            this.$confirm("", {
                title: "是否确定退出",
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                sessionStorage.removeItem("user")
                this.$router.push({
                    path: '/public/login',
                })
            })
        }
    },

}

</script>

<style scoped="scoped">
.Breadcrumb {
    background-color: #545c64;
    color: #ffffff;
}

.left_menu {
    width: 100%;
    box-sizing: border-box;
}

.router-link-active {
    text-decoration: none;
}

.el-page-header__content {
    color: white !important;
    font-size: 14px !important;
}

a {
    text-decoration: none;
}
</style>
