<template>

    <div>
        <router-view v-if="$route.meta.loginShow"></router-view>
        <ContainerVue v-if="$route.meta.mainShow">
            <template v-slot:main>
                <div v-title data-title="客户关系管理系统1.0"></div>
                <keep-alive :include="['UserListView', 'GetEveryDayFormCountView']">
                    <router-view></router-view>
                </keep-alive>
            </template>
        </ContainerVue>
    </div>

</template>

<script>
import ContainerVue from "./components/Container.vue";
export default {
    name: 'App',
    components: {
        ContainerVue
    },
}
</script>

<style lang="less">
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.el-message-box__btns {
    display: flex !important;
    flex-direction: row-reverse !important;
}

.el-message-box__btns button:nth-child(2) {
    margin-right: 10px;
}

.el-main {
    height: calc(100vh - 140px);
}

.el-aside {
    background-color: #263238 !important;
    border-right: 1px solid rgb(212, 212, 212) !important;
    overflow: hidden !important;
    box-shadow: 1px 5px 5px #cccbcb;
    box-sizing: border-box;
}

.el-menu {
    * {
        font-size: 14px !important;
        font-weight: 550 !important;
    }

    min-width: 200px !important;
}

.el-footer {
    text-align: center;
    background-color: #f4f6f7 !important;
    color: black !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.el-submenu .el-menu-item {
    max-width: 200px !important;
    min-width: 200px !important;
}

// .el-button {
//     background-color: #ff9000 !important;
//     border: #ff9000 solid 1px !important;
// }
</style>
