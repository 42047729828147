import Vue from "vue";

const fangdou = function (fun, wait) {
    let timer = null;
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(fun, wait);
    }
}

const xianliu = function (fun, wait) {
    let timer = null;
    return () => {
        console.log("timer 1 :", timer);
        if (!timer) {
            timer = setTimeout(() => {
                fun();
                console.log("timer 2 :", timer);
                timer = null;
            }, wait);
        }
    }
}

const testImportJs = function (aaa) {
    console.log("testImportJs success !", aaa)
}

function padZero(n) {
    return (n <= 9 ? n = '0' + n : n)

}

function dateFormat(time, format) {
    if (!format || format == "") {
        format = "YYYY-MM-DD hh:mm:ss"
    }

    const dt = new Date(time)

    const y = dt.getFullYear()
    const m = padZero(dt.getMonth() + 1)
    const d = padZero(dt.getDate())

    const hh = padZero(dt.getHours())
    const mm = padZero(dt.getMinutes())
    const ss = padZero(dt.getSeconds())

    return format.replace('YYYY', y)
        .replace('MM', m)
        .replace('DD', d)
        .replace('hh', hh)
        .replace('mm', mm)
        .replace('ss', ss)
}

export default function () {
    Vue.prototype.$fangdou = fangdou
    Vue.prototype.$xianliu = xianliu
    Vue.prototype.$testImportJs = testImportJs
    Vue.prototype.$dateFormat = dateFormat
}