<template>
    <div class="header_top">
        <div class="topLogo" style="font-size:26px; font-weight:600;color:#409eff;"> 表单后台 </div>
        <div>欢迎：<span>{{user.comp_name}}</span> 您目前是<span> {{user.level_name}} </span>!</div>
    </div>
</template>
<script>
export default {
    name: 'HeaderTop',
    data() {
        return {
            user: {},
            topImg: "https://static.boshuoit.com/static/img/2021-11-26/bdbkg.fw.png",
        }
    },
    methods: {},
    created() {
        let that = this
        // 从缓存获取user
        const user = sessionStorage.getItem('user')
        this.user = JSON.parse(user)
        switch (this.user.user_level) {
            case 1:
                this.user.level_name = "超级管理员"
                break;
            case 2:
                this.user.level_name = "管理员"
                break;
            case 3:
                this.user.level_name = "普通用户"
                break;
        }
    }
}

</script>

<style>
body {
    margin: 0px;
    padding: 0px;
}

.header_top span:nth-child(2) {
    color: #ff5600;
    font-weight: bold;
}
.header_top span:nth-child(3) {
    color: #409eff;
    font-weight: bold;
}

.header_top {
    background-color: #ffffff !important;
    color: black;
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 14px;
}

.el-header {
    height: 80px !important;
    background-color: #ffffff !important;
    color: #333;
    line-height: 60px;
    /* border-bottom: 1px solid rgb(172, 170, 170); */
    box-shadow: 1px 1px 7px #cccbcb;
}

.el-footer {
    background-color: #b3c0d1;
    color: #333;
    line-height: 60px;
}

.el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
}

.el-main {
    /*background-color: #E9EEF3;*/
    color: #333;
}

body > .el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}

.topImg {
    width: 201px;
    height: 58px;
}
</style>
